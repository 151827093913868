<template>
  <div>
    <div class="container">
      <div class="content-wrap content-wrap--with-slider">
        <div class="title">Выберите персонажа</div>
      </div>
    </div>
    <div class="slider-container">
      <swiper
        ref="mainSwiper"
        :key="swiperKey"
        class="swiper"
        :options="swiperOption"
      >
        <swiper-slide
          v-for="item in characters"
          :key="item.id"
          class="item-wrap"
        >
          <div
            class="item"
            :class="{ 'item--active': item.id === selectedCharacterId }"
            @click="selectCharacter(item.id)"
          >
            <div class="item-inner">
              <div class="item-img">
                <img :src="item.img" :alt="item.title" />
              </div>
              <div class="item-text">{{ item.title }}</div>
            </div>
          </div>
        </swiper-slide>
        <div class="productSliderWidget__pagination" slot="pagination">
          <div class="swiper-pagination"></div>
        </div>
      </swiper>
    </div>
    <div class="container">
      <div class="info" v-if="selectedCharacterId">
        <div class="row">
          <div class="col-auto d-none d-md-block">
            <div class="info__img">
              <img
                :src="selectedCharacter.smallImg"
                :alt="selectedCharacter.title"
              />
            </div>
          </div>
          <div class="col-12 col-md">
            <div class="info__title">{{ selectedCharacter.title }}</div>
            <div class="info__text">{{ selectedCharacter.text }}</div>
          </div>
          <div class="col-12 col-md-auto align-self-center">
            <a href="#" @click.prevent="setStep(steps.QUESTION)" class="btn"
              >Начать <img src="../assets/img/ico-play.svg"
            /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  name: "SelectCharacter",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      padding: 0,
      swiperKey: Math.random(),
    };
  },
  methods: {
    selectCharacter(id) {
      this.$store.dispatch("selectCharacter", id);
    },
    onResize() {
      this.padding = 0;
      this.padding =
        (document.querySelector("body").clientWidth -
          document.querySelector(".container").clientWidth) /
        2;
      this.swiperKey = Math.random();
    },
    setStep(step) {
      this.$store.dispatch("setStep", step);
    },
  },
  mounted() {
    this.onResize();
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  computed: {
    swiperOption() {
      return {
        shortSwipes: true,
        preventClicks: false,
        preventClicksPropagation: false,
        slidesPerView: "auto",
        spaceBetween: 0,
        grabCursor: true,
        slidesOffsetBefore: this.padding,
        slidesOffsetAfter: this.padding,
        centeredSlides: true,
        slideToClickedSlide: true,
        breakpoints: {
          768: {
            slidesPerView: "auto",
            spaceBetween: 0,
            centeredSlides: false,
            loop: false,
            shortSwipes: false,
            slideToClickedSlide: true,
          },
        },
        pagination: {
          el: ".swiper-pagination",
        },
        navigation: {
          nextEl: ".productSliderWidget__next",
          prevEl: ".productSliderWidget__prev",
        },
      };
    },
    characters() {
      return this.$store.state.characters;
    },
    selectedCharacter() {
      return this.$store.getters.selectedCharacter;
    },
    selectedCharacterId() {
      return this.$store.state.selectedCharacterId;
    },
    steps() {
      return this.$store.state.steps;
    },
  },
};
</script>

<style scoped lang="scss">
.slider-container {
  margin-bottom: 48px;
}
.content-wrap--with-slider {
  margin-bottom: 0;
  padding-bottom: 0;
}
.item-wrap {
  width: 352px;
}
.item {
  background-color: #f1f5f9;
  border: 1px solid #e2e8f0;
  text-align: center;
  border-radius: 32px;
  cursor: pointer;
  box-sizing: border-box;
  transition: border-color 0.35s;
  overflow: hidden;
  margin: 16px 16px 32px;
  &-img {
    margin-bottom: 10px;
  }
  &-text {
    font-size: 18px;
    font-family: "Stem", sans-serif;
  }
  &--active {
    background: linear-gradient(180deg, #ffffff 0%, #f8fafc 100%);
    border: 4px solid #e61c41;
    box-shadow: 0 8px 16px rgba(15, 23, 42, 0.06),
      0 16px 28px rgba(15, 23, 42, 0.08);
    .item-inner {
      border: 0;
    }
  }
}
.item-inner {
  border: 3px solid #f1f5f9;
  padding: 64px 32px;
}

.info {
  background: #ffffff;
  border: 1px solid #e2e8f0;
  box-sizing: border-box;
  box-shadow: 0 4px 48px rgba(15, 23, 42, 0.12);
  border-radius: 32px 32px 0 0;
  padding: 32px;
  position: fixed;
  bottom: 0;
  z-index: 10;
  &__img {
    max-width: 110px;
  }
  &__title {
    font-size: 20px;
    font-family: "Stem", sans-serif;
    margin-bottom: 4px;
  }
  &__text {
    font-size: 16px;
    max-width: 570px;
  }
}

@media (max-width: 768px) {
  .slider-container {
    margin-bottom: 0;
  }
  .info {
    position: relative;
    box-shadow: none;
    border: none;
    text-align: center;
    padding: 0 32px;

    &__text {
      font-size: 14px;
      margin-bottom: 32px;
    }
  }
  .item-text {
    display: none;
  }

  .item-wrap {
    width: 70vw;
  }
}
</style>
