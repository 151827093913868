<template>
  <div class="start-container">
    <div class="container">
      <div class="content-wrap">
        <div class="row">
          <div class="col-12 order-2 order-md-1">
            <div class="text">
              <img alt="dreams" src="/img/main/text1.svg" />
            </div>
          </div>
          <div class="col-12 order-1 order-md-2">
            <div class="img">
              <picture>
                <source
                  srcset="/img/main/img1.png"
                  media="(min-width: 600px)"
                />
                <img src="/img/main/img1-m.png" alt="main" />
              </picture>
            </div>
          </div>
          <div class="col-12 order-3">
            <div class="text-block">
              Тебе предстоит помочь нашим героям осуществить их планы. Соберись,
              им необходимы твоя интуиция и верные советы, чтобы мечта стала
              реальностью. Выбери свой ответ для лучшего результата.
            </div>
          </div>
        </div>
      </div>
      <div class="center">
        <a href="#" @click.prevent="setStep(steps.SELECT)" class="btn"
          >Выбрать персонажей</a
        >
      </div>
    </div>
  </div>
</template>

<script>
const imagesObject = [
  "/img/v1/v1.png",
  "/img/v1/q/v1-q1.png",
  "/img/v1/q/v1-q1-a.png",
  "/img/v1/q/v1-q2.png",
  "/img/v1/q/v1-q2-a.png",
  "/img/v1/q/v1-q3.png",
  "/img/v1/q/v1-q3-a.png",
  "/img/v1/q/v1-q3-alt.png",
  "/img/v1/q/v1-q3-alt-a.png",
  "/img/v1/q/v1-q4.png",
  "/img/v1/q/v1-q4-a.png",
  "/img/v1/q/v1-q4-alt.png",
  "/img/v1/q/v1-q4-alt-a.png",
  "/img/v1/q/v1-q5.png",
  "/img/v1/q/v1-q5-a.png",
  "/img/v1/q/v1-q6.png",
  "/img/v1/q/v1-q6-a.png",
  "/img/v1/q/v1-q7.png",
  "/img/v1/q/v1-q7-a.png",
  "/img/v1/v1-final.png",

  "/img/v2/v2.png",
  "/img/v2/q/v2-q1.png",
  "/img/v2/q/v2-q1-a.png",
  "/img/v2/q/v2-q2.png",
  "/img/v2/q/v2-q2-a.png",
  "/img/v2/q/v2-q3.png",
  "/img/v2/q/v2-q3-a.png",
  "/img/v2/q/v2-q4.png",
  "/img/v2/q/v2-q4-a.png",
  "/img/v2/q/v2-q5.png",
  "/img/v2/q/v2-q5-a.png",
  "/img/v2/q/v2-q6.png",
  "/img/v2/q/v2-q6-a.png",
  "/img/v2/q/v2-q7.png",
  "/img/v2/q/v2-q7-a.png",
  "/img/v2/v2-final.png",

  "/img/v3/v3.png",
  "/img/v3/q/v3-q1.png",
  "/img/v3/q/v3-q1-a.png",
  "/img/v3/q/v3-q2.png",
  "/img/v3/q/v3-q2-a.png",
  "/img/v3/q/v3-q3.png",
  "/img/v3/q/v3-q3-a.png",
  "/img/v3/q/v3-q4.png",
  "/img/v3/q/v3-q4-a.png",
  "/img/v3/q/v3-q4-alt.png",
  "/img/v3/q/v3-q4-alt-a.png",
  "/img/v3/q/v3-q5.png",
  "/img/v3/q/v3-q5-a.png",
  "/img/v3/q/v3-q6.png",
  "/img/v3/q/v3-q6-a.png",
  "/img/v3/q/v3-q7.png",
  "/img/v3/q/v3-q7-a.png",
  "/img/v3/q/v3-q8.png",
  "/img/v3/q/v3-q8-a.png",
  "/img/v3/v3-final.png",

  "/img/v4/v4.png",
  "/img/v4/q/v4-q1.png",
  "/img/v4/q/v4-q1-a.png",
  "/img/v4/q/v4-q2.png",
  "/img/v4/q/v4-q2-a.png",
  "/img/v4/q/v4-q3.png",
  "/img/v4/q/v4-q3-a.png",
  "/img/v4/q/v4-q4.png",
  "/img/v4/q/v4-q4-a.png",
  "/img/v4/q/v4-q4-alt.png",
  "/img/v4/q/v4-q4-alt-a.png",
  "/img/v4/q/v4-q5.png",
  "/img/v4/q/v4-q5-a.png",
  "/img/v4/q/v4-q6.png",
  "/img/v4/q/v4-q6-a.png",
  "/img/v4/q/v4-q6-alt.png",
  "/img/v4/q/v4-q6-alt-a.png",
  "/img/v4/q/v4-q7.png",
  "/img/v4/q/v4-q7-a.png",
  "/img/v4/v4-final.png",
];

export default {
  name: "Start",
  components: {},
  mounted() {
    Object.keys(imagesObject).map((key, index) => {
      const img = new Image();
      img.src = imagesObject[key];
    });
  },
  methods: {
    setStep(step) {
      this.$store.dispatch("setStep", step);
    },
  },
  computed: {
    steps() {
      return this.$store.state.steps;
    },
  },
};
</script>

<style scoped lang="scss">
.start-container {
  background-image: url(../assets/img/main/ellipse.svg);
  background-size: 100%;
  background-position: center bottom 110px;
  background-repeat: no-repeat;
}
.text-block {
  font-size: 15px;
  line-height: 26px;
  max-width: 540px;
  margin: 0 auto;
  text-align: center;
  padding: 0 16px 40px;
}
.text {
  margin: 0 auto 20px;
  text-align: center;
}
.img {
  margin: 0 auto 20px;
  text-align: center;
}
@media (max-width: 1440px) {
  .img {
    max-width: 560px;
  }
  .content-wrap {
    padding: 30px 0 30px;
    margin-bottom: 0px;
  }
  .start-container {
    background-position: center bottom 70px;
  }
}
@media (max-width: 768px) {
  .start-container {
    background-size: 200%;
    background-position: center top 240px;
    background-color: #f1f5f9;
    background-image: url(../assets/img/main/ellipse.svg),
      linear-gradient(180deg, #fff 0%, #fff 50%, #f1f5f9 100%);
  }
}
</style>
