import Vue from "vue";
import Vuex from "vuex";
import v1 from "../assets/data-v1.json";
import v2 from "../assets/data-v2.json";
import v3 from "../assets/data-v3.json";
import v4 from "../assets/data-v4.json";
Vue.use(Vuex);

const SET_SELECTED_CHARACTER = "SET_SELECTED_CHARACTER";
const SET_CURRENT_QUESTION = "SET_CURRENT_QUESTION";
const SET_STEP = "SET_STEP";

export const steps = {
  START: "START",
  SELECT: "SELECT",
  QUESTION: "QUESTION",
};

export default new Vuex.Store({
  state: {
    characters: [v1, v2, v3, v4],
    selectedCharacterId: 1,
    currentQuestion: 1,
    step: steps.START,
    steps,
  },
  mutations: {
    [SET_SELECTED_CHARACTER](state, id) {
      state.selectedCharacterId = id;
    },
    [SET_CURRENT_QUESTION](state, id) {
      state.currentQuestion = id;
    },
    [SET_STEP](state, step) {
      state.step = step;
    },
  },
  actions: {
    selectCharacter({ commit }, id) {
      commit(SET_SELECTED_CHARACTER, id);
    },
    nextQuestion({ commit }, id) {
      commit(SET_CURRENT_QUESTION, id);
    },
    setStep({ commit }, step) {
      commit(SET_STEP, step);
    },
  },
  getters: {
    selectedCharacter: (state) => {
      return state.characters.find((i) => i.id === state.selectedCharacterId);
    },
    currentQuestion: (state) => {
      return state.characters
        .find((i) => i.id === state.selectedCharacterId)
        ?.questions?.find((q) => q.id === state.currentQuestion);
    },
  },
  modules: {},
});
