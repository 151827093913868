<template>
  <div class="questions-wrap">
    <div class="container">
      <div class="content-wrap" v-if="selectedCharacterId">
        <div class="row">
          <div class="col-12 col-md-7">
            <img
              v-if="!final"
              :src="
                showQuestion
                  ? currentQuestion.img
                  : currentQuestion.img.replace('.png', '-a.png')
              "
              :alt="currentQuestion.title"
              class="question-img"
              :class="{ 'question-img--change': changeQuestion }"
            />
            <img
              v-if="final"
              :src="selectedCharacter.finalImg"
              :alt="selectedCharacter.title"
              class="question-img"
              :class="{ 'question-img--change': changeQuestion }"
            />
          </div>
          <div class="col-12 col-md-5 question-step-wrap" :style="{'height': h}">
            <transition name="fade">
              <div v-if="showQuestion && !final" class="question-step">
                <div class="question-text" v-html="currentQuestion.text"></div>
                <a href="#" @click="toggleStep" :onclick="'ym(85025623,\'reachGoal\',\'story' + selectedCharacterId + '_q' + currentQuestionNumber + '\');'" class="btn">Ответить</a>
              </div>
            </transition>
            <transition name="fade">
              <div v-if="!showQuestion && !final" class="question-step">
                <div class="question-number">
                  Вопрос: {{ currentQuestionNumber }} из {{ questionCount }}
                </div>
                <div class="question-title">
                  {{ currentQuestion.title }}
                </div>
                <div class="question-answers">
                  <div
                    v-for="answer in currentQuestion.answers"
                    :key="answer.title"
                    class="answer-item"
                    :class="{
                      'answer-item--active': answer.id === selectedAnswerId,
                    }"
                    @click="selectAnswer(answer)"
                  >
                    <div class="row no-gutters align-items-center">
                      <div class="col-auto">
                        <div class="answer-item__liter">{{ answer.liter }}</div>
                      </div>
                      <div class="col">{{ answer.title }}</div>
                    </div>
                  </div>
                </div>
                <div class="timer__title">Осталось времени:</div>
                <div class="timer">
                  <div class="row no-gutters align-items-center">
                    <div class="col-auto">
                      <div class="timer__ico">
                        <img src="../assets/img/ico-timer.svg" alt="timer" />
                      </div>
                    </div>
                    <div class="col">
                      <div class="timer__line">
                        <div
                          class="timer__line-inner"
                          :style="{
                            width: (currentTime / timeLimit) * 100 + '%',
                          }"
                        ></div>
                      </div>
                    </div>
                    <div class="col-auto">
                      <div class="timer__text">
                       - {{ timeLeft }} с
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
            <transition name="fade">
              <div v-if="final" class="question-step question-step--final">
                <div
                  v-if="!timeExceeded"
                  class="question-title"
                  v-html="selectedCharacter.finalText"
                ></div>
                <div
                  v-if="timeExceeded"
                  class="question-title"
                  v-html="selectedCharacter.finalTextAlt"
                ></div>
                <div
                  class="question-subscribe"
                  v-if="selectedCharacter.finalSubscribe"
                >
                  {{ selectedCharacter.finalSubscribe }}
                </div>
                <div class="row align-items-center" v-if="timeExceeded">
                  <div class="col-12 col-md-auto">
                    <a href="#" @click.prevent="setStep(steps.START)" class="btn btn--auto"
                      ><img src="../assets/img/ico-replay.svg" /> Начать
                      заново</a
                    >
                  </div>
                  <div class="col-12 col-md-auto">
                    <div class="share-wrap" v-if="!showShare">
                      <a href="#" class="btn btn--white btn--auto" @click.prevent="showShare = !showShare"
                        ><img src="../assets/img/ico-share.svg" /> Поделиться</a
                      >
                    </div>
                    <div class="share-wrap" v-else>
                      <ShareNetwork
                        network="facebook"
                        :url="link"
                        title="Я помог героям осуществить их планы, помоги и ты! Им необходимы твоя интуиция и верные советы, чтобы мечта стала реальностью."
                      >
                        <div class="share-icon share-icon--facebook"></div>
                      </ShareNetwork>
                      <ShareNetwork
                        network="vk"
                        :url="link"
                        title="Я помог героям осуществить их планы, помоги и ты! Им необходимы твоя интуиция и верные советы, чтобы мечта стала реальностью."
                      >
                        <div class="share-icon share-icon--vk"></div>
                      </ShareNetwork>
                      <ShareNetwork
                        network="telegram"
                        :url="link"
                        title="Я помог героям осуществить их планы, помоги и ты! Им необходимы твоя интуиция и верные советы, чтобы мечта стала реальностью."
                      >
                        <div class="share-icon share-icon--tg"></div>
                      </ShareNetwork>
                      <ShareNetwork
                        network="whatsapp"
                        :url="link"
                        title="Я помог героям осуществить их планы, помоги и ты! Им необходимы твоя интуиция и верные советы, чтобы мечта стала реальностью."
                      >
                        <div class="share-icon share-icon--wa"></div>
                      </ShareNetwork>
                      <ShareNetwork
                        network="odnoklassniki"
                        :url="link"
                        title="Я помог героям осуществить их планы, помоги и ты! Им необходимы твоя интуиция и верные советы, чтобы мечта стала реальностью."
                      >
                        <div class="share-icon share-icon--ok"></div>
                      </ShareNetwork>
                    </div>

                  </div>
                </div>
                <div v-if="!timeExceeded">
                  <div class="share-wrap" v-if="!showShare">
                    <a
                      href="#"
                      class="btn btn--white"
                      @click.prevent="showShare = !showShare"
                      ><img src="../assets/img/ico-share.svg" alt="" />
                      Поделиться</a
                    >
                  </div>
                  <div class="share-wrap" v-else>
                    <ShareNetwork
                      network="facebook"
                      :url="link"
                      title="Я помог героям осуществить их планы, помоги и ты! Им необходимы твоя интуиция и верные советы, чтобы мечта стала реальностью."
                    >
                      <div class="share-icon share-icon--facebook"></div>
                    </ShareNetwork>
                    <ShareNetwork
                      network="vk"
                      :url="link"
                      title="Я помог героям осуществить их планы, помоги и ты! Им необходимы твоя интуиция и верные советы, чтобы мечта стала реальностью."
                    >
                      <div class="share-icon share-icon--vk"></div>
                    </ShareNetwork>
                    <ShareNetwork
                      network="telegram"
                      :url="link"
                      title="Я помог героям осуществить их планы, помоги и ты! Им необходимы твоя интуиция и верные советы, чтобы мечта стала реальностью."
                    >
                      <div class="share-icon share-icon--tg"></div>
                    </ShareNetwork>
                    <ShareNetwork
                      network="whatsapp"
                      :url="link"
                      title="Я помог героям осуществить их планы, помоги и ты! Им необходимы твоя интуиция и верные советы, чтобы мечта стала реальностью."
                    >
                      <div class="share-icon share-icon--wa"></div>
                    </ShareNetwork>
                    <ShareNetwork
                      network="odnoklassniki"
                      :url="link"
                      title="Я помог героям осуществить их планы, помоги и ты! Им необходимы твоя интуиция и верные советы, чтобы мечта стала реальностью."
                    >
                      <div class="share-icon share-icon--ok"></div>
                    </ShareNetwork>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>
        <div v-if="final">
          <div class="banner">
            <div class="row">
              <div class="col-12 col-md order-2 order-md-1">
                <div class="banner__title">Кредит наличными</div>
              </div>
              <div class="col-12 col-md-auto order-1 order-md-2">
                <img src="../assets/img/hcb-logo.svg" alt="" />
              </div>
            </div>
            <div class="row align-items-end">
              <div class="col">
                <div class="row">
                  <div class="col-auto">
                    <div class="banner__value">5,9</div>
                  </div>
                  <div class="col-auto">
                    <div class="banner__sign">%</div>
                    <div class="banner__title">годовых</div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-auto">
                <a
                  :href="
                    !timeExceeded
                      ? selectedCharacter.bannerLink
                      : selectedCharacter.bannerLinkAlt
                  "
                  class="btn"
                  onclick="ym(85025623,'reachGoal','final_banner_link');"
                  >Оформить</a
                >
              </div>
            </div>
            <div class="banner__hint">
              ООО “ХКФ Банк.” Ген. лицензия ЦБ РФ №316.
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-none">
      <div id="tooltip-template" class="tooltip">
        <div class="tooltip-text">
          Кредит со ставкой 5,9% годовых в Банке Хоум Кредит
        </div>
        <div>
          <a
            href="[link]"
            target="_blank"
            class="btn btn--outline"
            onclick="ym(85025623,'reachGoal','tooltip_link');"
            >Подробнее</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";

export default {
  name: "Questions",
  components: {},
  data() {
    return {
      showQuestion: true,
      currentQuestionNumber: 1,
      selectedAnswerId: undefined,
      final: false,
      changeQuestion: false,
      timeLimit: 20,
      currentTime: 0,
      timer: undefined,
      timeExceeded: false,
      link: location.href,
      showShare: false,
      h: "auto",
    };
  },
  methods: {
    toggleStep(e) {
      e.preventDefault();
      this.showQuestion = !this.showQuestion;
    },
    selectAnswer(answer) {
      this.selectedAnswerId = answer.id;
      setTimeout(this.nextQuestion, 500, answer.nextQuestionId);
    },
    nextQuestion(id) {
      this.showQuestion = true;
      this.selectedAnswerId = undefined;
      this.currentQuestionNumber += 1;
      this.changeQuestion = true;
      setTimeout(() => {
        this.changeQuestion = false;
      }, 350);
      if (this.currentQuestionNumber > this.questionCount) {
        this.final = true;
        this.resetTimer();
        setTimeout(() => {
          this.initTooltip();
          this.calcHeight();
        }, 100);
      } else {
        this.$store.dispatch("nextQuestion", id);
        this.initTimer();
        setTimeout(() => {
          this.initTooltip();
          this.calcHeight();
        }, 100);
      }
    },
    initTimer() {
      this.resetTimer();
      this.currentTime = 0;
      this.timer = setInterval(() => {
        if (this.showQuestion === false) {
          this.currentTime += 1;
        }
        if (this.timeLimit < this.currentTime) {
          this.resetTimer();
          setTimeout(() => {
            this.timeExceeded = true;
            this.final = true;
            setTimeout(() => {
              this.initTooltip();
            }, 100);
          }, 350);
        }
      }, 1000);
    },
    resetTimer() {
      clearInterval(this.timer);
    },
    initTooltip() {
      tippy(".tooltip", {
        trigger: "click",
        content(reference) {
          const link = reference.getAttribute("data-href");
          return document
            .getElementById("tooltip-template")
            .innerHTML.replace("[link]", link);
        },
        allowHTML: true,
        arrow: false,
        placement: "bottom",
      });
    },
    setStep(step) {
      this.$store.dispatch("setStep", step);
    },
    calcHeight() {
      if (window.innerWidth < 768) {
        this.h =
          document.querySelector(".question-step")?.clientHeight + "px" || "auto";
      } else {
        this.h = "auto";
      }

    },
  },
  mounted() {
    this.initTimer();
    this.initTooltip();
    this.calcHeight();
    window.addEventListener("resize", this.calcHeight);
  },
  computed: {
    selectedCharacter() {
      return this.$store.getters.selectedCharacter;
    },
    selectedCharacterId() {
      return this.$store.state.selectedCharacterId;
    },
    currentQuestion() {
      return this.$store.getters.currentQuestion;
    },
    questionCount() {
      return this.selectedCharacter.questions.filter((q) => !q.alt).length;
    },
    steps() {
      return this.$store.state.steps;
    },
    timeLeft() {
      let t = this.timeLimit - this.currentTime;
      t = t < 0 ? "00" : (t >= 10 ? t : "0" + t);
      return "0:" + t;
    },
  },
};
</script>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.35s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active до версии 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.question-step-wrap {
  position: relative;
}
.question-step {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.question-text {
  font-family: "Stem", sans-serif;
  font-size: 28px;
  line-height: 40px;
  margin-bottom: 32px;
}

.question-title {
  font-family: "Stem", sans-serif;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 32px;
}

.question-subscribe {
  margin-bottom: 32px;
  font-style: italic;
}

.question-img {
  opacity: 1;
  transition: opacity 0.15s;
  &--change {
    opacity: 0;
    transition: opacity 0.2s;
  }
}

.answer-item {
  background: #f1f5f9;
  border: 1px solid #e2e8f0;
  box-sizing: border-box;
  border-radius: 16px;
  padding: 16px;
  margin-bottom: 16px;
  color: #334155;
  cursor: pointer;
  transition: all 0.35s;
  position: relative;
  padding-right: 50px;
  &__liter {
    background: #e2e8f0;
    border-radius: 8px;
    font-size: 16px;
    font-family: "Stem", sans-serif;
    margin-right: 16px;
    width: 32px;
    text-align: center;
    height: 32px;
    line-height: 32px;
    transition: all 0.35s;
  }
  &:before {
    width: 24px;
    height: 24px;
    content: "";
    display: block;
    background-image: url(../assets/img/ico-select.svg);
    background-size: cover;
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    transition: opacity 0.35s;
  }
  &--active {
    background-color: #fff;
    box-shadow: 0 4px 12px rgba(15, 23, 42, 0.08);
    &:before {
      opacity: 1;
    }
    .answer-item__liter {
      background-color: #e61c41;
      color: #fff;
    }
  }
}

.timer {
  background: #fff;
  border: 1px solid #e2e8f0;
  box-sizing: border-box;
  border-radius: 16px;
  padding: 20px;
}
.timer__title {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 12px;
  color: #64748b;
  margin-top: 64px;
}
.timer__text {
  color: #64748b;
  font-weight: 600;
  font-family: "Stem", sans-serif;
  font-size: 16px;
  width: 62px;
}
.timer__line {
  background: #cbd5e1;
  border-radius: 24px;
  overflow: hidden;
  height: 6px;
  margin: 0 20px;
  &-inner {
    transition: width 0.35s;
    height: 6px;
    background: #e61c41;
    width: 0;
  }
}

.banner {
  background: #f1f5f9;
  padding: 60px;
  font-family: "Stem", sans-serif;
  margin-top: 100px;
  &__title {
    font-size: 40px;
    line-height: 50px;
    color: #293d62;
  }
  &__all {
    font-weight: bold;
    font-size: 40px;
    line-height: 52px;
    color: #e61c41;
  }
  &__sign {
    font-weight: bold;
    font-size: 56px;
    line-height: 72px;
    color: #e61c41;
    position: relative;
    top: 3px;
  }
  &__value {
    font-weight: 700;
    font-size: 144px;
    line-height: 100%;
    color: #e61c41;
    margin-right: -12px;
  }
  &__hint {
    color: #283b63;
    opacity: 0.5;
    font-size: 18px;
    margin-top: 40px;
  }
}

@media (max-width: 768px) {
  .question-step-wrap {
    min-height: 40vh;
  }
  .question-step {
    position: absolute;
    top: 50px;
    transform: none;
    width: calc(100% - 32px);
    &--final {
      position: relative;
      width: 100%;
    }
  }
  .question-text {
    font-size: 20px;
    line-height: 28px;
  }
  .question-title {
    line-height: 28px;
    margin-bottom: 16px;
    font-size: 20px;
  }
  .timer__title {
    display: none;
  }
  .timer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    border-radius: 0;
    box-shadow: 0px 4px 24px rgb(15 23 42 / 12%);
  }
  .questions-wrap {
    background-image: url(../assets/img/main/ellipse.svg);
    background-size: 200%;
    background-position: center top -120px;
    background-repeat: no-repeat;
  }

  .banner {
    padding: 24px;
    margin-top: 100px;
    img {
      display: block;
      margin: 0 auto 24px;
    }
    &__title {
      font-size: 29px;
      line-height: 100%;
      text-align: center;
      letter-spacing: -0.01em;
      margin-bottom: 24px;
    }
    &__all {
      font-weight: bold;
      font-size: 40px;
      line-height: 52px;
      color: #e61c41;
    }
    &__sign {
      font-size: 40px;
      line-height: 100%;
      top: 5px;
      margin-bottom: 5px;
    }
    &__value {
      font-size: 84px;
      line-height: 100%;
      margin-right: -12px;
      margin-bottom: 36px;
    }
    &__hint {
      font-size: 11px;
      line-height: 22px;
      text-align: center;
    }
  }
}
</style>
